import './App.css';
import { Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Immigration from './Pages/Immigration/Immigration';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import Ielts from './Pages/Ielts/Ielts';
import Pte from './Pages/Pte/Pte';
import Visa from './Pages/ImmigrationPages/Visa/Visa';
import SocialMedia from './data/SocialMedia/SocialMedia';
import TouristVisa from './Pages/ImmigrationPages/TouristVisa/TouristVisa';
import StudentVisa from './Pages/ImmigrationPages/StudentVisa/StudentVisa';

function App() {
  return (
    <>
    <Navbar/>
    <Routes>
    <Route path='/' element={<Header/>} />
    <Route path='/About' element={<About/>} />
    <Route path='/Contact' element={<Contact/>} />
    <Route path='/Immigration' element={<Immigration/>} />
    <Route path='/Ielts' element={<Ielts/>} />
    <Route path='/Pte' element={<Pte/>} />
    <Route path='/Visa' element={<Visa/>} />
    <Route path='/Pte' element={<Pte/>} />
    <Route path='/TouristVisa' element={<TouristVisa/>} />
    <Route path='/StudentVisa' element={<StudentVisa/>} />
    </Routes>
    <SocialMedia/>
    <Footer/>
    </>
  );
}

export default App;
