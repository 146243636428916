import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./VideoSlider.css";
import ReactPlayer from "react-player";

const videos = ["sd", "esg", "esg", "esg"];

const VideoSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="sliderr">
      <div data-aos="fade-up" className="a-heading">
        <span>Testamonials</span>
        <div className="a-line"></div>
      </div>
      <Slider {...settings}>
        {videos.map((video, index) => (
          <div key={index} className="video-container">
            <ReactPlayer
              url={video}
              width="70%"
              height="500px"
              controls
              className="react-player"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default VideoSlider;
