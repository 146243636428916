import React from "react";
import "./TouristVisa.css";
import Images from "../../../Images";

const TouristVisa = () => {
  return (
    <div className="touristvisa">
      <div className="main-img1"></div>

      <div className="process">
        <div className="p-heading">
          <h1>How it Works?</h1>
        </div>
        <div className="p-para">
          <p>
            Discovering your perfect career or stream cannot get easier than our
            unique, scientific approach to career guidance.
          </p>
        </div>
        <div className="p-rec">
          <div className="rec">
            <img src={Images.Inquiry} alt="" />
            <div className="rec-text">
              <span>Enquiry</span>
              <div className="text-para">
                <p>Welcome! Your journey starts here...</p>
              </div>
            </div>
          </div>
          <div className="rec">
            <img src={Images.Expert} alt="" />
            <div className="rec-text">
              <span>Expert counselling</span>
              <div className="text-para">
                <p>
                Our expert will talk to you and guides you based on your requirements.
                </p>
              </div>
            </div>
          </div>
          <div className="rec">
            <img src={Images.Documentation} alt="" />
            <div className="rec-text">
              <span>Documentation</span>
              <div className="text-para">
                <p>
                Expert assistance in arranging the requirements.
                </p>
              </div>
            </div>
          </div>
          <div className="rec">
            <img src={Images.Eligibility} alt="" />
            <div className="rec-text">
              <span>Eligibility</span>
              <div className="text-para">
                <p>Sign-up with us to check your eligibility</p>
              </div>
            </div>
          </div>
          <div className="rec">
            <img src={Images.Processing} alt="" />
            <div className="rec-text">
              <span>Proccesing</span>
              <div className="text-para">
                <p>
                Assists you in every step while filing the visa application.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="choose">
        <div className="p-heading">
            <h1>Why choose Language & Visa as your Visa Partner?</h1>
          </div>
          <div className="p-para">
            <p>
            We transform you to become Global Indian!
            </p>
          </div>
          <div className="c-rec">
            <div className="rec">
              <img src={Images.Applicants} alt="" />
              <div className="rec-text">
              <span>1000+ Applicants</span>
              <div className="text-para">
              <p>1000+ successful visa applicants</p>
              </div>
              </div>
            </div>
            <div className="rec">
              <img src={Images.Professionals} alt="" />
              <div className="rec-text">
              <span>Expert Professionals</span>
              <div className="text-para">
              <p>Experienced and dedicated professionals for every type of visa</p>
              </div>
              </div>
            </div>
            <div className="rec">
              <img src={Images.Services} alt="" />
              <div className="rec-text">
              <span>Personalized Services</span>
              <div className="text-para">
              <p>Personalized services with a dedicated agent appointed to you.</p>
              </div>
              </div>
            </div>
            <div className="rec">
              <img src={Images.Online} alt="" />
              <div className="rec-text">
              <span>Online Services</span>
              <div className="text-para">
              <p>Online services with a dedicated agent appointed to you.</p>
              </div>
              </div>
            </div>
          </div>
          <div className="apply">
            <a href="/Contact">Apply Now</a>
          </div>
        </div>

      <div className="type-visa">
        <p>
          <span>Travel visa/ Tourist visa</span>
          <br />
          <br />
          <p>
            Travel visas/Tourist visas are official travel documents that are
            given to foreign visitors for vacation or for sightseeing. For
            example, if you are from India and looking to visit the US, you need
            to apply for a US tourist visa. Every country has its own visa
            policies for tourists, and their corresponding embassies and
            consulates to help you get a visa for short-term visits.
          </p>
          <br />

          <p>
            These Travel visas/ Tourist visas have a limited validity period and
            foreign visitors travelling with this visa are not allowed to do any
            business-related activities in another country. However, the
            eligibility requirements, application criteria and other required
            documents can vary from country to country.
          </p>
          <br />

          <span>
            Quick & Efficient Travel guidelines of Travel visa/Tourist visa
          </span>
          <br />
          <br />
          <p>
            While visiting a foreign country with a Visitor visa, the following
            guidelines have to be followed:
          </p>
          <br />
          <p>
            -Should not work with a tourist visa.
            <br />
            -No Business activities under a tourist visa.
            <br />
            -Not allowed to study while you have a tourist visa.
            <br />
            -You cannot become a permanent resident with a tourist visa.
            <br />
          </p>
          <br />

          <span>Most Popular Tourist Visas</span>
          <br />
          <br />
          <p>
            -Australia Tourist Visa
            <br />
            -Canada Tourist Visa
            <br />
            -UK Tourist Visa
            <br />
            -US Tourist Visa
            <br />
            -Schengen Visa
            <br />
            -Belgium Visitor Visa
            <br />
            -Austria Tourist Visa
            <br />
            -Denmark Tourist Visa
            <br />
            -Finland Tourist Visa
            <br />
            -Hungary Visit Visa
            <br />
            -Malta Visit Visa
            <br />
            -Netherlands Visit Visa
            <br />
            -Luxembourg Visit Visa
            <br />
            -Italy Tourist Visa
            <br />
          </p>
          <br />

          <span>How to Apply for a Tourist Visa?</span>
          <br />
          <br />
          <p>To apply for a tourist visa, follow these steps:</p>
          <br />

          <p>
            Step 1: Locate an embassy/consulate near you.
            <br />
            Step 2: Submit all the documents required.
            <br />
            Step 3: Pay the required tourist visa fee.
            <br />
            Step 4: Attend the visa interview.
            <br />
          </p>
          <br />

          <span>Documents Required for a Tourist Visa</span>
          <br />
          <br />
          <p>The documents required for a Tourist visa are listed below:</p>
          <br />

          <p>
            -Tourist visa application form.
            <br />
            -Three or six months' valid passport
            <br />
            -Passport Photograph.
            <br />
            -Travel insurance.
            <br />
            -Proof of Funds
            <br />
            -Accommodation proof
            <br />
            -Proof of a booked return flight ticket.
            <br />
            -Proof of paid visa fee.
            <br />
            -Invitation letter.
            <br />
            -Civil documents (marriage certificate, birth certificate, etc.)
            <br />
            -Supporting documents.
            <br />
          </p>
          <br />
        </p>
        <br />
        <p>
          <span>Tourist Visa Validity</span>
          <br />
          <br />
          <p>
            The validity of a tourist visa is usually issued for 30 days.
            However, some countries may issue a tourist visa for a year with
            multiple entries— still, you’re allowed to stay only 30 days per
            entry.
          </p>
          <br />

          <span1>How Language & Visa Can Help You?</span1>
          <br />
          <br />
          <p>
            -Identifying the best strategy to get your visit visa
            <br />
            -Advising you on finances to be shown
            <br />
            -Advising you on papers to be presented
            <br />
            -Help in filling in forms
            <br />
            -Review all your papers before their submission
            <br />
          </p>
          <br />

          <p>
            Applying for a visa can be intimidating. Language & Visa has the knowledge,
            experience and robust processes to help you navigate complex visa
            procedures more confidently. We have a high success rate and
            best-in-class service.
          </p>
        </p>
      </div>
    </div>
  );
};

export default TouristVisa;
