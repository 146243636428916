import React from "react";
import "./Uni.css";
import Images from "../../Images";

const Uni = () => {
  return (
    <div className="universities">
      <div className="uni">
        <div className="uni-heading">
          <h1>Get Expert Visa Assistance For:</h1>
        </div>
        <div className="flags">
          <div className="flag">
            <img src={Images.canada} alt="" />
            <span>Canada</span>
          </div>
          <div className="flag">
            <img src={Images.usa} alt="" />
            <span>U.S.A</span>
          </div>
          <div className="flag">
            <img src={Images.uk} alt="" />
            <span>U.K</span>
          </div>
          <div className="flag">
            <img src={Images.australia} alt="" />
            <span>Australia</span>
          </div>
          <div className="flag">
            <img src={Images.newzealand} alt="" />
            <span>New Zealand</span>
          </div>
        </div>
      </div>

      <div className="colleges">
        <div className="col-heading">
          <h1>Some of the colleges we have tie-up with:</h1>
        </div>
        <div className="coll">
          <div className="col">
            <img src={Images.college} alt="" />
          </div>
          <div className="col">
            <img src={Images.college1} alt="" />
          </div>
          <div className="col">
            <img src={Images.college2} alt="" />
          </div>
          <div className="col">
            <img src={Images.college3} alt="" />
          </div>

          <div className="col">
            <img src={Images.college4} alt="" />
          </div>
          <div className="col">
            <img src={Images.college5} alt="" />
          </div>
          <div className="col">
            <img src={Images.college6} alt="" />
          </div>
          <div className="col">
            <img src={Images.college7} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Uni;
