import {
    faFacebook,
    faInstagram,
    faYoutube,
  } from "@fortawesome/free-brands-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import React from "react";
  import "./SocialMedia.css";
  
  const SocialMedia = () => {
    return (
      <div>
        <div className="socialmedia">
          <div className="s-content">
            <div className="social-content">
              <p>
                Don’t Miss To Follow Us On
              </p>
              <span>Social Media</span>
            </div>
          </div>
  
          <div className="s-icons">
            <a href="">
              {" "}
              <FontAwesomeIcon icon={faInstagram} />{" "}
            </a>
            <a href="#">
              {" "}
              <FontAwesomeIcon icon={faFacebook} />{" "}
            </a>
            <a href="">
              {" "}
              <FontAwesomeIcon icon={faYoutube} />{" "}
            </a>
          </div>
        </div>
      </div>
    );
  };
  
  export default SocialMedia;  