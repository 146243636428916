import logo from "./Assets/logo.png";
import aboutpic from "./Assets/Untitled.png";
import aboutpic1 from "./Assets/landv.png";
import gallery from "./Assets/gallery/IMG_0612.jpeg";
import gallery1 from "./Assets/gallery/IMG_0613.jpeg";
import gallery2 from "./Assets/gallery/IMG_0616.jpeg";
import gallery3 from "./Assets/gallery/IMG_0617.jpeg";
import gallery4 from "./Assets/gallery/IMG_0618.jpeg";
import gallery5 from "./Assets/gallery/1.jpg";
import gallery6 from "./Assets/gallery/2.jpg";
import gallery7 from "./Assets/gallery/3.jpg";
import gallery8 from "./Assets/gallery/4.jpg";
import gallery9 from "./Assets/gallery/5.jpg";
import gallery10 from "./Assets/gallery/6.jpg";
import gallery11 from "./Assets/gallery/7.jpg";
import Books from "./Assets/books.png";
import abpic from "./Assets/background1.jpg";
import usa from "./Assets/flags/usa.png";
import uk from "./Assets/flags/uk.png";
import australia from "./Assets/flags/australia.png";
import newzealand from "./Assets/flags/newzealand.png";
import canada from "./Assets/flags/canada.png";
import college from "./Assets/universities/1.png";
import college1 from "./Assets/universities/2.png";
import college2 from "./Assets/universities/3.png";
import college3 from "./Assets/universities/4.png";
import college4 from "./Assets/universities/5.png";
import college5 from "./Assets/universities/6.png";
import college6 from "./Assets/universities/7.png";
import college7 from "./Assets/universities/8.png";
import ielts from "./Assets/background.png";
import girl1 from "./Assets/Testo/girl1.jpg";
import girl2 from "./Assets/Testo/girl2.jpg";
import girl3 from "./Assets/Testo/girl3.jpg";
import girl4 from "./Assets/Testo/girl4.jpg";
import girl5 from "./Assets/Testo/girl5.jpg";
import boy1 from "./Assets/Testo/boy1.jpg";
import Inquiry from "./Assets/visa/Inquiry.webp";
import Applicants from "./Assets/visa/applicants.webp";
import Eligibility from "./Assets/visa/Eligibility.webp";
import Online from "./Assets/visa/online.webp";
import Expert from "./Assets/visa/ExpertCounselling.webp";
import Professionals from "./Assets/visa/professionals.webp";
import Services from "./Assets/visa/services.webp";
import Processing from "./Assets/visa/Processing.webp";
import Documentation from "./Assets/visa/Documentation.webp";
import Lvisa from "./Assets/visa/lvisa.png";
import servestudents from "./Assets/visa/servestudents.webp"
import rightcourse from "./Assets/visa/rightcourse.webp"

export default {
  logo,
  aboutpic,
  aboutpic1,
  gallery,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,
  gallery11,
  Books,
  abpic,
  usa,
  uk,
  canada,
  australia,
  newzealand,
  college,
  college1,
  college2,
  college3,
  college4,
  college5,
  college6,
  college7,
  ielts,
  girl1,
  girl2,
  girl3,
  girl4,
  girl5,
  boy1,
  Inquiry,
  Applicants,
  Eligibility,
  Online,
  Expert,
  Professionals,
  Services,
  Processing,
  Documentation,
  Lvisa,
servestudents,
rightcourse,
};
