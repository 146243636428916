import React from "react";
import "./Faq.css";
import { Immidata } from "../immidata";
import { useState } from "react";

const Faq = () => {
  const data = Immidata;
  const [open, setOpen] = useState();

  const toggle = (i) => {
    if (open === i) {
      return setOpen(null);
    }
    setOpen(i);
  };

  return (
    <div className="faq">
      <div className="op-content">
        <div data-aos="fade-up" className="c-title">
          <h2>Frequently Asked Questions</h2>
        </div>
      </div>
      <div className="faq-wrapper">
        {data.map((item, i) => {
          return (
            <div className="faq-accordion" onClick={() => toggle(i)}>
              <div className="faq-title">
                <h2>{item.Question}</h2>
                <span>{open === i ? "-" : "+"}</span>
              </div>
              <div className={open === i ? "content show" : "content"}>
                {" "}
                {item.Answer}{" "}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faq;
