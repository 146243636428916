export const Immidata = [
  {
    Question:
      "1. When will I require a visa?",
    Answer:
      "When you’ll require a visa is determined by where you want to go. If your home country and the country to which you intend to visit have a visa agreement, you will most likely not need to apply for a visa ahead of time. If your home country does not have a visa agreement with your planned destination, you will need to apply for a visa before departure.",
  },
  {
    Question: "2. Why do I require a visa in order to travel?",
    Answer:
      "If you want to travel to a country that does not have a visa regime similar to your own, you’ll need a visa. Many nations have visa regulations and agreements that allow inhabitants of those countries to travel freely between them without requiring a visa.",
  },
  {
    Question: "3. What is a visa policy and how does it work?",
    Answer:
      "A country’s visa policy is a set of rules that determines who is allowed and who is not allowed to enter the country. The policy may allow passport holders from one nation to enter without a visa, but not those from another. The majority of visa rules are bilateral, meaning that two countries will enable their nationals to travel visa-free, however this is not always the case. When it comes to setting visa policies, there are no hard and fast laws. However, diplomatic links with the other country, the history of illegal immigration from the country, and cost and tourism factors are some determining factors.",
  },
  {
    Question: "4. What are the reasons for visa restrictions in certain countries? ",
    Answer:
      "Visa limitations are in place to keep track of and control the flow of visitors in and out of a nation, as well as to prevent illegal immigration and other criminal activity. By requiring travelers to apply for a visa, the authorities are able to thoroughly scrutinize potential visitors. Individuals may, for example, attempt to migrate to a country with superior economic chances in order to work there illegally.",
  },
  {
    Question: "5. What are the different types of Visas?",
    Answer:
      "There are different types of Visas that are available based on your requirement and purpose of travel overseas such as Student Visa, Work Visa, Investor Visa, Business Visa, Dependent Visa, and Visitor Visa, etc.",
  },
  {
    Question:
      "6. What are the required documents for the Visa interview?",
    Answer:
      "The documents required for a Visa interview will depend on the nation and type of Visa that you are applying to. The documents that are most commonly required are: Original Passport with a minimum of 6 months of validity after arrival to the overseas nation, All expired Passports, 1 photograph both hard and digital copy as per the specification, The Visa application confirmation page stamped at the VAC, A valid receipt of Visa fee payment, Printout of appointment letter for the Visa interview",
  },
  {
    Question: "7. How long does it take to get a Visa?",
    Answer:
      "The Visa processing times are variable depending on the category of Visa and your location. It can take around a few weeks to some months.",
  },
  {
    Question:
      "8. What exactly is meant by ‘visa’?",
    Answer:
      "By the term ‘visa’ is meant an entry permit for a short and temporary visit that must not be more than 90 days in duration.",
  },
];
