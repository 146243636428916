import React from "react";
import "./StudentVisa.css";
import Images from "../../../Images";

const StudentVisa = () => {
  return (
    <div className="studentvisa">
      <div className="main-img"></div>
      <div className="first-para">
        <p>
          Study abroad allows every student to discover and broaden their
          horizons. Become an adaptable global contender by Studying Abroad!
        </p>
      </div>

      <div className="process">
        <div className="p-heading">
          <h1>How it Works?</h1>
        </div>
        <div className="p-para">
          <p>
            Discovering your perfect career or stream cannot get easier than our
            unique, scientific approach to career guidance.
          </p>
        </div>
        <div className="p-rec">
          <div className="rec">
            <img src={Images.Inquiry} alt="" />
            <div className="rec-text">
              <span>Enquiry</span>
              <div className="text-para">
                <p>Welcome! Your journey starts here...</p>
              </div>
            </div>
          </div>
          <div className="rec">
            <img src={Images.Expert} alt="" />
            <div className="rec-text">
              <span>Expert counselling</span>
              <div className="text-para">
                <p>
                Our expert will talk to you and guides you based on your requirements.
                </p>
              </div>
            </div>
          </div>
          <div className="rec">
            <img src={Images.Documentation} alt="" />
            <div className="rec-text">
              <span>Documentation</span>
              <div className="text-para">
                <p>
                Expert assistance in arranging the requirements.
                </p>
              </div>
            </div>
          </div>
          <div className="rec">
            <img src={Images.Eligibility} alt="" />
            <div className="rec-text">
              <span>Eligibility</span>
              <div className="text-para">
                <p>Sign-up with us to check your eligibility</p>
              </div>
            </div>
          </div>
          <div className="rec">
            <img src={Images.Processing} alt="" />
            <div className="rec-text">
              <span>Proccesing</span>
              <div className="text-para">
                <p>
                Assists you in every step while filing the visa application.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="s-process">
        <div className="p-heading">
          <h1>Why choose Language & Visa study counsellor?</h1>
        </div>
        <div className="p-para">
          <p>
          Helps the students to execute their educational experience with intelligence and integrity...
          </p>
        </div>
        <div className="sp-rec">
          <div className="s-rec">
            <img src={Images.rightcourse} alt="" />
            <div className="s-rec-text">
              <span>Right course. Right path</span>
              <div className="text-para">
                <p>Choosing the right course plays a crucial role in Studying Abroad!</p>
              </div>
            </div>
          </div>
          <div className="s-rec">
            <img src={Images.Lvisa} alt="" />
            <div className="s-rec-text">
              <span>One stop shop</span>
              <div className="text-para">
                <p>
                Language & Visa guides you at every step of your journey (from admissions to post-landing support).
                </p>
              </div>
            </div>
          </div>
          <div className="s-rec">
            <img src={Images.servestudents} alt="" />
            <div className="s-rec-text">
              <span>Serve students not universities</span>
              <div className="text-para">
                <p>
                We do not partner with any universities but provides unbiased advice to our students.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="type-visa">
        <span>Why study abroad?</span>
        <br />
        <p>
          -3 million student visas issued every year
          <br />
          -3,500 Top Universities
          <br />
          -2 lakh+ Courses
          <br />
          -Up to $2.1 Million Scholarships
          <br />
          -92% acceptance rate
          <br />
          -2-8 years of post-study work permits
          <br />
        </p>
        <br />
        <span>Study abroad consultants</span>
        <br />
        <p>
          Studying abroad is one of the most transformative and life-changing
          experiences. Being the best study abroad consultant with more than 25
          years of experience, Language & Visa helps you to make the most of this huge
          investment in time and cost with its proven strategy, which will help
          you gain admission to universities in leading countries like the US,
          UK, Canada, Australia, and other countries.
        </p>
        <br />
        <p>
          As a leading study abroad consultant in India, Language & Visa offers free
          career counseling and career planning advice. Our team of counselors
          helps you choose an apt study abroad program based on your career
          choice. We will help you make an informed decision.
        </p>
        <br />
        <p>
          We do not partner with universities and are unbiased in our student
          recommendations. This transparency and our end-to-end support make us
          the preferred vendor for students seeking to study abroad. Our Campus
          Ready Solution is popular among students and working professionals as
          a one-stop solution for all their overseas career plans.
        </p>
        <br />
        <span>Best Universities to Study Abroad</span>
        <br />
        <p>Some of the best universities to study abroad are:</p>
        <br />
        <p>
          -Harvard University
          <br />
          -University of Oxford
          <br />
          -Stanford University
          <br />
          -Massachusetts Institute of Technology (MIT)
          <br />
          -University of Cambridge
          <br />
          -California Institute of Technology (CalTech)
          <br />
          -Princeton University
          <br />
          -Yale University
          <br />
          -UCL
          <br />
        </p>
        <br />
        <p>
          According to the QS ranking 2024, the UK has many top-ranked
          universities, like the University of Oxford and the University of
          Cambridge. In the US, universities like Stanford University,
          Massachusetts Institute of Technology (MIT), Princeton University,
          California Institute of Technology (CalTech), and Harvard University
          are the best in quality education, infrastructure, and global
          standards.
        </p>
        <br />
        <span>Best countries to study abroad</span>
        <br />
        <p>
          Foreign universities in the United States, the United Kingdom, Canada,
          France, Australia, etc., have highly reputed institutions, and these
          nations are home to a large percentage of the world’s top
          universities.
        </p>
        <br />
        <span>Study in the US</span>
        <br />
        <p>
          The United States of America has the best educational system in the
          world. Its universities are well-regarded, most listed in the QS World
          University rankings. It is the most secure country in which to study.
          An Indian student can succeed in the United States because of its
          blend of opportunity, economy, and culture.
        </p>
        <br />
        <p>
          *Willing to study in the US? Language & Visa is here to assist you in all the
          procedures.
        </p>
        <br />
        <p>
          With over 4,000 world-class colleges, the United States provides
          diverse academic degrees and courses. The United States of America has
          great programs for international students that are widely recognized
          for their excellence.
        </p>
        <br />
        <span>Study in the UK</span>
        <br />
        <p>
          The United Kingdom boasts one of the world's most well-known higher
          education systems, with some of the greatest universities in the
          world.
        </p>
        <br />
        <p>
          *Willing to study in the UK? Language & Visa is here to assist you in all the
          procedures.
        </p>
        <br />
        <p>
          In the United Kingdom, you can study almost every subject you can
          think of at a diploma, bachelor’s, master’s, or doctoral level.
          Undergraduate degrees can take one to four years to complete, and
          master’s degrees can take one to two years.
        </p>
        <br />
        <span>Study in Germany</span>
        <br />
        <p>
          For Indian students, Germany is one of Europe's most popular study
          locations. This country is one of the most cost-effective locations,
          with high-quality education that does not break the bank. Its finest
          universities and colleges give excellent education, yet German
          authorities charge overseas students only a small administration fee
          and other basic charges per semester. Germany has more than ten
          institutions in the top 200 QS world university rankings. Indian
          students can choose from over 350 universities that provide more than
          800 courses.
        </p>
        <br />
        <p>
          *Willing to study in Germany? Language & Visa is here to assist you in all the
          procedures.
        </p>
        <br />
        <span>Study in Canada</span>
        <br />
        <p>
          For Indian students, Canada has become one of the most popular study
          destinations. In recent years, the number of students traveling to
          Canada for higher education has expanded dramatically. Canada’s
          education system is strong, with degrees and diplomas available in
          various subjects. Canada has a lower cost of education than the United
          States. International students have access to a greater number of
          scholarships, as well as the ability to work part-time off campus.
        </p>
        <br />
        <p>
          *Willing to study in Canada? Language & Visa is here to assist you in all the
          procedures.
        </p>
        <br />
        <span>Study in Singapore</span>
        <br />
        <p>
          Singapore is home to some of the world’s best management schools. As a
          result, Singapore is a popular study abroad location for Indian
          students pursuing management degrees. The leading management colleges
          in Singapore provide new and innovative management programs that
          appeal to Indian students.
        </p>
        <br />
        <p>
          The government covers 75% of overseas students’ tuition fees in
          exchange for them working in the country for three years. This gives
          students work experience once they finish their education.
        </p>
        <br />
        <span>Study abroad courses</span>
        <br />
        <p>
          Studying abroad offers students a unique blend of academic and
          cultural experiences. Courses range from traditional disciplines like
          arts, sciences, and humanities to specialized programs in fields like
          international business, environmental studies, and indigenous
          cultures. Many institutions also offer language immersion courses,
          enhancing linguistic skills while providing firsthand cultural
          insights.
        </p>
        <br />
        <span1>MBA abroad</span1>
        <br />
        <p>
          MBA is one of the most popular courses, but it is important to
          consider the reasons for doing an MBA abroad as well as the type of
          university you will attend. These factors and the university’s
          reputation can help open doors. As a result, it is critical to
          carefully select a study location before to applying. There are
          various factors which need to be considered, including cost,
          post-study work options, and job prospects when you decide to do an
          MBA abroad.
        </p>
        <br />
        <span1>MS abroad</span1>
        <br />
        <p>
          MS is one of the most popular courses for overseas students due to the
          weight that an MS degree carries on your resume. All of the world’s
          leading universities offer outstanding MS programs which attract the
          brightest brains. However, this makes it much more difficult for
          students to choose their university. It is better to get expert advice
          when making your choice.
        </p>
        <br />
        <span1>MBBS abroad</span1>
        <br />
        <p>
          Many countries considered the best option for MBBS are known for
          delivering not just economical options but also high-quality
          education. MBBS in a foreign country also promises better prospects.
          Many countries now provide high-quality medical education at low
          costs.
        </p>
        <br />
        <span1>Engineering abroad</span1>
        <br />
        <p>
          Engineering programs are among the most popular following high school,
          with many options. If you want to pursue a degree in engineering, you
          might be wondering which country is the best to study in. Every
          country has its unique engineering courses, such as comprehensive
          programs or research-oriented courses.
        </p>
        <br />
        <span1>PhD abroad</span1>
        <br />
        <p>
          A good PhD should push the academic field’s boundaries. As a student,
          this can often entail moving out of the country, so millions of
          postgraduates opt to study for their PhD abroad each year: to find the
          greatest expertise and facilities for their doctoral research. For an
          international PhD, you’ll have a lot of options to select from.
        </p>
        <br />
        <span>Benefits of studying abroad</span>
        <br />
        <p>
          -Studying abroad can help your career open up to multiple
          possibilities.
          <br />
          -Employers increasingly prioritize graduates with foreign experience
          and education in today’s globalized society.
          <br />
          -Learning new languages, appreciating other cultures, overcoming the
          hardships of living in another country, and gaining a better
          understanding of the globe are all benefits you will gain when you
          decide to study abroad.
          <br />
          -When hiring, all of these qualities are sought by organizations, and
          they will only grow more significantly.
          <br />
          -Many students study abroad to obtain a better education than they
          could receive in their native country. Studying abroad opens the door
          to better employment chances by enhancing knowledge, broadening
          experience, and developing a diverse set of highly valued talents
          worldwide. Your odds of landing a job that pays well are always good.
          <br />
          -You are separated from your friends and family when you study abroad.
          This will train you to be self-sufficient and self-sufficient. You’ll
          meet new people and enhance your study skills. You’ll also learn to
          manage your limited financial and other resources. These experiences
          will prepare you for the upcoming career and personal challenges.
          <br />
          -When studying in a foreign nation, you cannot communicate in the
          local language. Your speaking and communication abilities would
          undoubtedly improve as a result of this.
          <br />
          -You tend to challenge things and yourself when living in a setting
          outside your comfort zone. This is a fantastic approach to acquiring
          confidence since it teaches you how to survive on your own, even in
          adverse circumstances.
          <br />
          -Going abroad will surely broaden your social circle. Because you have
          connections with people from all over the world, you can be exposed to
          fresh opportunities.
          <br />
        </p>
        <br />
        <span>Best Courses to Study Abroad</span>
        <br />
        <p>Some of the best courses you can study abroad include:</p>
        <br />
        <p>
          -Computer Science
          <br />
          -Medicine and Healthcare
          <br />
          -Business Administration
          <br />
          -Engineering and Technology
          <br />
          -International Business
          <br />
          -Management and Leadership
          <br />
          -International Relations
          <br />
          -Mathematics
          <br />
        </p>
        <br />
        <p>Popular courses to study abroad:</p>
        <br />
        <p>
          -Accounting
          <br />
          -Social Sciences
          <br />
          -Law
          <br />
          -Hospitality
          <br />
          -Environmental Sciences
          <br />
          -Tourism
          <br />
        </p>
        <br />
        <p>
          Courses in huge demand include creative courses like films, arts,
          fashion, and design. Alternatively, if you are planning to study a
          different stream, you can even opt for courses like criminal
          psychology and other courses.
        </p>
        <br />
        <p>
          Most foreign universities start primary intake in September. Depending
          on the course of interest, the universities you plan to choose, and
          the country you decide to study, select any course that helps in
          career growth and empowerment.
        </p>
        <br />
        <span>How to get a scholarship to study abroad?</span>
        <br />
        <p>
          Yes, you can get a 100% scholarship to study abroad. Anyone who meets
          the specific criteria can avail of scholarships. Various scholarships
          are designed for specific groups of students based on certain
          qualifications or eligibility standards. These standards indicate the
          necessary qualifications an applicant must possess to be considered.
          It's essential to review these requirements to ensure the scholarship
          aligns with your profile, preventing unnecessary efforts on unsuitable
          options.
        </p>
        <br />
        <span>Requirements to apply for a Student visa</span>
        <br />
        <p>
          Once you have selected the universities/courses based on the
          geographical areas or the subject, you can see how far they match your
          chosen criteria. This will help you make a comparison between your
          choices.
        </p>
        <br />
        <p>
          Look for the following information to make an informed comparison
          between colleges:
        </p>
        <br />
        <p>
          -University ranking
          <br />
          -Start dates of available programs
          <br />
          -Content of the course
          <br />
          -Teaching methodology
          <br />
          -Career prospects for the course
          <br />
          -Campus life and activities
          <br />
          -Accommodation options
          <br />
          -Admission requirements
          <br />
          -Course affordability
          <br />
        </p>
        <br />
        <p>
          When it comes to choosing a course, cost is a major consideration. As
          previously stated, examine the actual course fees, scholarship
          opportunities, and funding options. This is critical for budgeting,
          whether you need to apply for a loan or explore other options. Look at
          scholarship options in the countries you have selected. You must also
          consider the living expenses in the country.
        </p>
        <br />
        <p>
          The ranking is important if you want to select the right university.
          Universities or colleges are ranked based on their quality of
          teaching, research options, and global outlook. A high-ranked
          university will give you a valuable learning experience. It also means
          better job prospects.
        </p>
        <br />
        <p>
          When you choose to study abroad, you must apply for a student visa.
          Get the information for visa requirements and deadlines. You can get
          this information on the university's official website and confirm it
          with the local embassy or consulate.
        </p>
        <br />
        <p>
          Examine the admission requirements for the country you wish to study
          in. Check whether you need to take additional exams such as GMAT, SAT,
          or GRE for the course or need to qualify with English proficiency
          tests.
        </p>
        <br />
        <p>
          You’ll need the following documents to apply to universities in order
          to study abroad:
        </p>
        <br />
        <p>
          -Application Form – The application form is the most crucial document
          because it contains all of your personal and professional information.
          An application form must be filled out accurately. Always double-check
          your application before sending it to the university where you wish to
          study.<br />
          <br />
          -Statement of purpose (SOP) - This is the most significant part of
          your application and will mostly discuss your background, reasons for
          pursuing your program at the specified university, and career
          ambitions. Spend a lot of time on your SOP because it will help your
          application stand out among the thousands of others. Take the help of
          an overseas education consultant to complete this requirement.<br />
          <br />
          -Academic transcripts - This is essentially your academic record,
          which will include any courses you’ve taken and your grades, credits,
          and degree received (it is easily available from your university).<br />
          <br />
          -Letter of Recommendation (LOR) - A letter of recommendation (LOR) is
          an academic letter written by one of your professors or managers that
          discusses your abilities, accomplishments, experience, and usefulness
          to your college or professional organization. This letter allows the
          admissions committee to gain insight into your life and make an
          informed decision about your admission.<br />
          <br />
          -Resume - A CV or resume will provide a detailed overview of your
          academic and professional history. Include all of your degrees,
          certificates, internships, and relevant professional experience in
          your resume.<br />
          <br />
          -Test Scores - Your test scores must be submitted with your
          application. The results of English language competency exams, such as
          the IELTS, are required by most countries and institutions. You may
          need to take other exams to study abroad, such as the SAT or GRE,
          depending on your choice of location and institution.<br />
          <br />
          -Essays - Some colleges may need you to submit a study abroad essay to
          demonstrate your commitment to your plans. This essay might be a
          fantastic opportunity to make a wonderful first impression and
          demonstrate to the university administration that you are prepared and
          committed to pursuing the course in their organization.<br />
          <br />
          -Valid passport - Finally, a passport should be valid for at least six
          months when filing for admission and a visa.<br />
          <br />
        </p>
        <br />
        <span>How to apply for a Student Visa?</span>
        <br />
        <p>
          -Get to know the visa requirements - Different countries have varying
          visa requirements, the embassy of the nation you want to study in
          should be your first stop.
          <br />
          -Get a confirmation from your university - Before traveling to your
          destination, you should always apply for a visa. You must wait until
          you have received a confirmed offer from a university before
          submitting the visa application, and you may be required to apply in
          person at an embassy.
          <br />
          -Identify the visa you should apply for - The actual name and type of
          visa you’ll need varies by nation, but you’ll certainly need a
          non-immigrant student/study visa. This suggests that you do not intend
          to stay in that nation permanently and that your stay is only for
          studying.
          <br />
          -You may require proof of your place on a program from the university
          or college you plan to attend before you apply for your visa.
          <br />
          -Get the required papers from your university - Contact the university
          or college to find out the visa you need to apply for and request that
          they send you the appropriate papers.
          <br />
          -Make your application - You should be ready to apply once your
          university or college has advised you of the suitable visa you need
          and any papers you require to submit. Ask how to apply for a visa at
          your host country’s embassy or consulate or browse their website. The
          visa will be revoked if fraudulent information is provided or crucial
          facts are omitted throughout the application process.
          <br />
          -Processing time - Depending on the country and your nationality, visa
          processing can take anything from a few days to 3 months. Make sure
          you give yourself enough time to get your visa in order since rushing
          an application is not recommended and may result in you losing your
          spot.
          <br />
        </p>
        <br />
        <p>
          Get the help of an overseas immigration consultant to help you with
          the application process.
        </p>
        <br />
        <span>Student Visa costs</span>
        <br />
        <p></p>
        <br />
        <span>Student visa processing time</span>
        <br />
        <p></p>
        <br />
        <span>Language & Visa - The best study abroad consultants</span>
        <br />
        <p>
          Language & Visa has the scale and expertise to help students access
          world-class education and then turn this accomplishment into a career
          launchpad. Our package of services helps students build their dream
          life whether they want to work, settle or just study abroad.
          <br />
          -Free counseling: Professional counseling to help you choose the right
          course and college
          <br />
          -Coaching: Ace your IELTS, TOEFL, PTE, GRE, GMAT & SAT tests with our
          live classes to help you score well on the exams to study abroad.
          <br />
          -Course recommendation: Unbiased advice that puts you on the path to
          success
          <br />
          -SOP/LOR: Both SOP/LOR that give an insight into yourself can
          significantly strengthen your application
          <br />
          -Admission support: Complete support during the process from choosing
          the right course to help with the entrance exams to the application
          process.
          <br />
        </p>
        <br />
        <span1>Why choose Language & Visa as your study abroad consultant?</span1>
        <br />
        <p>
          We take pride in providing unbiased advice to students. We are not in
          partnership with any university and are an independent overseas
          education consultant.
        </p>
        <br />
        <span1>We work for you</span1>
        <br />
        <p>
          Unlike most overseas education consultants, we work for you, not for a
          university. As we don’t depend on commissions from colleges or
          universities, we are free to advise you on what is best for you.
          <br />
          Moreover, as we are not financed by a bank or a VC or are listed on
          the stock market, we have no pressure to make sales. This freedom
          allows us to think freely and come up with a solution that works for
          you, your life and your career.
          <br />
          As an Indian company, we understand the aspirations, the pains and
          tribulations that our families go through to give us a good education.
          As parents normally fund education with a student loan, we realize
          that we should not burden them for its repayment. Language & Visa charts a
          program so that you should, upon graduation, be able to repay your
          student loan.
          <br />
          This kind of thinking not only allows you to repay your debt but also,
          in doing so you become financially independent and also increase your
          confidence and self-esteem.
        </p>
        <br />
        <span1>We offer you great value</span1>
        <br />
        <p>
          We bundle all our services so that it comes to you at a great value
          and convenience. For a small fee you get India’s best career
          consultant working on your side for a lifetime. The package includes
          counseling, course selection, documentation, coaching for entrance
          exams & student visa application.
          <br />
          When you see the unit price of our services, you will see how
          reasonable and fair we are.
        </p>
        <br />
        <span>We make it a great investment</span>
        <br />
        <p>
          What does your money buy you? Just a degree? You should be getting
          more than that.
          <br />
          You can get not only a degree but also a skill that will get you not
          only a job but also a PR visa.
          <br />
          Did you know some courses are eligible for a PR Visa and others are
          not? Once you enter a country on the wrong course or degree, you will
          not only find it difficult but also very expensive.
          <br />
          If you plan and strategize well you can make it a great investment
          that can change your life positively. You have one chance to make it
          happen and you have to do it right the first time.
        </p>
        <br />
        <span1>We offer a relationship of a lifetime</span1>
        <br />
        <p>
          We don’t see you as a one-time customer. We would like to be with you
          for a lifetime in every step of your journey even after you graduate.
          Sometimes we feel that this is when you need us the most – when you
          have just landed and need someone at the airport, when you have a
          migration issue or need to find a job abroad.
        </p>
        <br />
        <span1>Our counseling is life-changing</span1>
        <br />
        <p>
          Our Y-Path for students charts a path for you to become a Global
          Indian who makes his parents, friends, community and country proud.
          <br />
          Y-Path is a result of Language & Visa’ years of counseling experience that has
          helped thousands of Indians to settle abroad.
          <br />
          Nobody understands overseas careers like we do. When we say that we
          mean that nobody understands it in its totality – in all its
          implications from funding to immigration to finding a job. To us
          admissions is the easy clerical part – the harder part is to chalk a
          career path for you.
        </p>
        <br />
        <span1>Our processes are integrated</span1>
        <br />
        <p>
          We are not only a one-stop-shop, all our services are integrated to
          ensure a smooth flow from one department to the other and one stage to
          the other. Even after you graduate you can continue using our customer
          service.
          <br />
          Language & Visa's adoption of cutting-edge technology, including Salesforce.com
          and Genesys, allows us to provide you with an enhanced customer
          experience. We are just a call, e-mail, chat, or even a small drive
          away.
        </p>
        <br />
        <span1>We offer you Premium Member & Verified Status</span1>
        <br />
        <p>
          When you sign up with us, you appear in our open resume bank as a
          premium member which allows potential employers to contact you
          directly. To help them verify who you are, you will show up as a
          Language & Visa Verified student, which means we would do the basic checks
          about your identity and credentials and endorse you.
        </p>
        <br />
        <span1>We market you for a job upon graduation</span1>
        <br />
        <p>
          Before you realize it, you will have graduated and will be searching
          for a job. We will provide you all the support needed for the job
          search.
        </p>
        <br />
        <span1>You become part of the Global Indian Community</span1>
        <br />
        <p>
          We will help you network with other Indians living abroad. As a member
          of our own network you will also have the opportunity to share your
          experiences that will help other students.
        </p>
        <br />
        <span1>We offer you immigration support</span1>
        <br />
        <p>
          We are presumably the world’s largest immigration firm, and no other
          firm has the experience we have as an overseas education consultant
          filing new applications. Several thousands of Indians have settled
          abroad using our services. We can guide you through all your student
          visa and immigration-related matters upon your graduation.{" "}
        </p>
      </div>
    </div>
  );
};

export default StudentVisa;
