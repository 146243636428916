import React from "react";
import "./Immigration.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faChevronRight,
  faDesktop,
  faSchool,
} from "@fortawesome/free-solid-svg-icons";
import Uni from "../../data/Uni/Uni";
import Faq from "../../data/Faq/Faq";

const Immigration = () => {
  return (
    <div className="immi">
      <div class="home">
        <div class="text">
          <span>Let's Plan The Trip Of Your Dreams.</span>
          <p>
            Unlock Your Global Opportunities: Your Gateway to IELTS and PTE
            Success and Immigration Excellence.
          </p>
        </div>
      </div>

      <div class="visa">
        <div class="heading">
          <p>WELCOME TO Language and Visa</p>
          <div class="arc"></div>
          <span>
            A One-Stop Solution For All <br />
            Your Visa Needs
          </span>
        </div>
        <div class="visa-types">
          <div class="type">
            <FontAwesomeIcon icon={faSchool} size="2xl" className="i" />
            <span>Student Visa Process</span>
            <a href="/StudentVisa">
              Learn More &nbsp;
              <FontAwesomeIcon icon={faChevronRight} />
            </a>
          </div>

          <div class="type">
            <FontAwesomeIcon icon={faBuilding} size="2xl" className="i" />
            <span>Tourist Visa Process</span>
            <a href="/TouristVisa">
              Learn More &nbsp;
              <FontAwesomeIcon icon={faChevronRight} />
            </a>
          </div>

          <div class="type">
            <FontAwesomeIcon icon={faDesktop} size="2xl" className="i" />
            <span>Skilled Immigration Process</span>
            <a href="/Visa">
              Learn More &nbsp;
              <FontAwesomeIcon icon={faChevronRight} />
            </a>
          </div>
        </div>
      </div>

      <div className="section">
        <Uni />
      </div>

      {/* <div class="testomonials">
        <div class="testo">
          <div class="testo-content">
            <div class="linee"></div>
            <div class="content">
              <span>this that hsdiewihrfia</span>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Eligendi quaerat consectetur repellat harum ipsam animi,
                doloremque repellendus natus iure, rerum modi officiis
                reiciendis adipisci minima ea labore, ducimus quidem impedit!
              </p>
            </div>
          </div>
          <div class="testo-info">
            <img src="./Assets/gallery/logo1.webp" alt="" />
            <span>
              Mr.Hiemdall,<p1>Australia</p1>
            </span>
            <p>March 3, 2022</p>
          </div>
        </div>

        <div class="testo">
          <div class="testo-content">
            <div class="linee"></div>
            <div class="content">
              <span>this that hsdiewihrfia</span>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Eligendi quaerat consectetur repellat harum ipsam animi,
                doloremque repellendus natus iure, rerum modi officiis
                reiciendis adipisci minima ea labore, ducimus quidem impedit!
              </p>
            </div>
          </div>
          <div class="testo-info">
            <img src="./Assets/gallery/logo1.webp" alt="" />
            <span>
              Mr.Hiemdall,<p1>Australia</p1>
            </span>
            <p>March 3, 2022</p>
          </div>
        </div>
        <div class="testo">
          <div class="testo-content">
            <div class="linee"></div>
            <div class="content">
              <span>this that hsdiewihrfia</span>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Eligendi quaerat consectetur repellat harum ipsam animi,
                doloremque repellendus natus iure, rerum modi officiis
                reiciendis adipisci minima ea labore, ducimus quidem impedit!
              </p>
            </div>
          </div>
          <div class="testo-info">
            <img src="./Assets/gallery/logo1.webp" alt="" />
            <span>
              Mr.Hiemdall,<p1>Australia</p1>
            </span>
            <p>March 3, 2022</p>
          </div>
        </div>
      </div> */}

      <div className="faq">
        <Faq />
      </div>

      <div class="contact">
        <div class="contact-info">
          <div class="info-content">
            <p>Welcome to Language and Visa</p>
            <div class="line"></div>
            <br />
            <span>
              For More Queries!!!! <br />
              Fill The Details Given Below.
            </span>
          </div>
          <div class="form">
            <form action="">
              <div class="two-inputs">
                <input type="text" placeholder="First Name" />
                <input type="text" placeholder="Last Name" />
              </div>
              <div class="two-inputs">
                <input type="text" placeholder="Country Code" />
                <input type="text" placeholder="Mobile Number" />
              </div>
              <div class="two-inputs">
                <input type="text" placeholder="Marital Status" />
                <input type="text" placeholder="Monthly Income" />
              </div>
              <div class="two-input">
                <input type="text" placeholder="Email Address" />
                <input type="text" placeholder="Current Occupation" />
              </div>
              <button className="formbtn">Send Now</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Immigration;
