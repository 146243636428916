import React from "react";
import "./Visa.css";
import Images from '../../../Images'

const Visa = () => {
  return (
    <div className="visa">
      <div className="visa-solution">
        <div className="v-heading">
          <h1>Visa Services</h1>
        </div>

        <div className="contact-visa">
          <p>Get Visa Solutions from No. 1 Immigration Consultancy.</p>
          {/* <form action="" className="forms">
            <div className="input">
            <input type="text" placeholder="Name"/>
          <input type="text" placeholder="Visa"/>
            </div>
            <div className="input">
            <input type="text" placeholder="Country"/>
          <input type="text" placeholder="Phone no."/>
            </div>
            <div className="input">
            <input type="text" placeholder="Email Id"/>
            </div>
          <button>Submit</button>
          </form> */}
        </div>

        <div className="process">
          <div className="p-heading">
            <h1>Visa Process</h1>
          </div>
          <div className="p-para">
            <p>
              Language & Visa has more profound knowledge, experience, and robust
              processes to help you navigate complex visa procedures and file
              your visa application more confidently.
            </p>
          </div>
          <div className="p-rec">
            <div className="rec">
              <img src={Images.Inquiry} alt="" />
              <div className="rec-text">
              <span>Inquiry</span>
              <div className="text-para">
              <p>Welcome! Your journey starts here...</p>
              </div>
              </div>
            </div>
            <div className="rec">
              <img src={Images.Expert} alt="" />
              <div className="rec-text">
              <span>Expert counselling</span>
              <div className="text-para">
              <p>Our team of dedicated counselors is here to help you find your path.</p>
              </div>
              </div>
            </div>
            <div className="rec">
              <img src={Images.Documentation} alt="" />
              <div className="rec-text">
              <span>Documentation</span>
              <div className="text-para">
              <p>All your document will compiled to create strong application</p>
              </div>
              </div>
            </div>
            <div className="rec">
              <img src={Images.Eligibility} alt="" />
              <div className="rec-text">
              <span>Eligibility</span>
              <div className="text-para">
              <p>Sign-up with us to check your eligibility</p>
              </div>
              </div>
            </div>
            <div className="rec">
              <img src={Images.Processing} alt="" />
              <div className="rec-text">
              <span>Proccesing</span>
              <div className="text-para">
              <p>All your documents will compiled to create strong application</p>
              </div>
              </div>
            </div>
          </div>
        </div>

        <div className="choose">
        <div className="p-heading">
            <h1>Why choose Language & Visa as your Visa Partner?</h1>
          </div>
          <div className="p-para">
            <p>
            We transform you to become Global Indian!
            </p>
          </div>
          <div className="c-rec">
            <div className="rec">
              <img src={Images.Applicants} alt="" />
              <div className="rec-text">
              <span>1M+ Applicants</span>
              <div className="text-para">
              <p>1Million successful visa applicants</p>
              </div>
              </div>
            </div>
            <div className="rec">
              <img src={Images.Professionals} alt="" />
              <div className="rec-text">
              <span>Expert Professionals</span>
              <div className="text-para">
              <p>Experienced and dedicated professionals for every type of visa</p>
              </div>
              </div>
            </div>
            <div className="rec">
              <img src={Images.Services} alt="" />
              <div className="rec-text">
              <span>Personalized Services</span>
              <div className="text-para">
              <p>Personalized services with a dedicated agent appointed to you.</p>
              </div>
              </div>
            </div>
            <div className="rec">
              <img src={Images.Online} alt="" />
              <div className="rec-text">
              <span>Online Services</span>
              <div className="text-para">
              <p>Online services with a dedicated agent appointed to you.</p>
              </div>
              </div>
            </div>
          </div>
          <div className="apply">
            <a href="/Contact">Apply Now</a>
          </div>
        </div>

        <div className="type-visa">
          <span>Visa Solutions From India’s #1 Visa Consultancy</span>
          <br />
          <p>
            A visa is a legal document enabling the bearer to enter a foreign
            country legally. The bearer’s passport is normally stamped or
            affixed with the visa.
          </p>
          <br />
          <span>Types of Visa</span>
          <br />
          <p>
            A variety of visas are available, each of which grants the bearer
            permission to live, work, tour, or study in the country. Here is a
            list of common types of visas:
          </p>
          <br />
          <a>Permanent residency visa</a>
          <br />
          <p>
            A PR visa, or Permanent Resident Visa, allows you to travel to a
            nation, stay for a period of time, and then seek for citizenship. In
            some countries, obtaining a PR visa eventually leads to citizenship.
          </p>
          <br />
          <a>Visit/tourist visa </a>
          <br />
          <p>
            Visit visas are non-immigrant visas that are required if an
            individual wishes to enter a country temporarily for business,
            tourism, or transit when traveling by air or ship.
          </p>
          <br />
          <a>Study visa</a>
          <br />
          <p>
            Students enrolled in recognized educational institutions in foreign
            countries are granted a student visa. Non-immigrant visas are
            available for students. Students who intend to pursue higher
            education in another nation must apply for a student visa in order
            to study there.
          </p>
          <br />
          <a>Work visa</a>
          <br />
          <p>
            A work visa allows you to travel to another nation and work for a
            set amount of time. For work permit visas, different nations may
            have distinct procedures and qualifying requirements. These visas
            are available in two types: temporary and permanent. A temporary
            work visa is only valid for a set amount of time and must be renewed
            if the visa holder wants to extend his stay. In most cases, the
            employer is the one who applies for a work permit visa. The employer
            will submit a visa application to the immigration authority of a
            specific country.
          </p>
          <br />
          <a>Business visa</a>
          <br />
          <p>
            Travelers who intend to perform business operations and meetings
            that are not tied to work in that nation are issued business visas
            by government officials in that country. A business visa permits the
            holder to do the following:<br />
            <br />
            -Participate in meetings.
            <br />
            -Attend conferences and business events
            <br />
            -Investigate new business ventures and opportunities
          </p>
          <br />
          <a>Investor visa</a>
          <br />
          <p>
            Countries issue investor visas to encourage people with an
            established business track record and a genuine desire to start a
            business in another country to invest in their country. This can be
            a new business endeavor or the purchase of an existing international
            company.<br />
            <br />
            Immigrant Investor Programs are designed to attract potential
            immigrant investors with sufficient personal wealth and managerial
            skills. Any business, with the exception of those that operate for
            the purpose of gaining interest or capital gains, qualifies for this
            program.<br />
            <br />
            Every country that grants an investment visa has its unique set of
            criteria and restrictions.
          </p>
          <br />
          <a>Dependent visa</a>
          <br />
          <p>
            A dependent visa allows spouses and children to go to another
            country to accompany or join a family member who has a matching
            Visa.<br />
            <br />
            Dependents of a lawful migrant to a country, such as spouses,
            dependent children, or parents, can enter and reside in the country
            with a dependent visa. Dependents of migrants can seek for a visa to
            join them in the nation.<br />
            <br /> Applying for a visa can be a stressful prospect. With an
            endless train of paperwork and documentation, it can quickly become
            overwhelming. Language & Visa has the knowledge, experience, and robust
            processes to help you navigate complex visa procedures and file your
            visa application with greater confidence.<br />
            <br />
            Each country offers different types of visa base on the purpose of
            your visit. Though you can apply for a visa to most countries
            online. It will take some time for you to understand the process,
            get the required documents, and fill the application form. You must
            ensure that you meet the eligibility requirements and requirements
            specific to the country you wish to move to.<br />
            <br /> Remember there are different requirements for a student visa,
            a tourist visa, a work visa, etc. You must understand the specific
            requirements of the visa you are applying for and fill the
            application form and follow the other processes to get your visa.<br />
            <br /> This is where the advice of a visa counselor or a visa
            advisor will be valuable to help you understand the different visas
            available and the specific requirements for your visa. A visa
            counselor will help you with the application process to successfully
            procure your visa.<br />
            <br />
            <span1>
              Here are the general steps you must follow to get a visa:
            </span1><br />
            <br />
            -Choose your visa
            <br />
            -Prepare your application
            <br />
            -Attend an appointment at the country’s embassy or consulate
            <br />
            -Get a decision on your application<br />
            <br />
            Following the process and getting it right can sometimes get
            overwhelming. A better option is to consult an immigration expert
            who can help you in an efficient way.<br />
            <br />
            <span1>How Language & Visa Can Help You?</span1><br />
            <br />
            -Identifying the best strategy to get your visa
            <br />
            -Advising you on finances to be shown
            <br />
            -Advising you on papers to be presented
            <br />
            -Help in filling of forms
            <br />
            -Review of all your papers before their submission
            <br />
            <br />
            Language & Visa has the knowledge, experience and robust processes to help
            you navigate complex visa procedures and file your visa application
            with greater confidence
          </p>
        </div>
      </div>
    </div>
  );
};

export default Visa;
